import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { RouterProps } from "@tanstack/react-router";
import { RouterProvider } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { ReactTagManager } from "react-gtm-ts";
import { Toaster } from "react-hot-toast";
import type { FunctionComponent } from "./common/types";

// import { TanStackRouterDevelopmentTools } from "./components/utils/development-tools/TanStackRouterDevelopmentTools";

const queryClient = new QueryClient();

const App = ({ router }: RouterProps): FunctionComponent => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const userAgent: string =
			navigator.userAgent ||
			navigator.vendor ||
			(window as { opera?: string }).opera ||
			"";
		const isMobileDevice = /iphone|ipad|ipod|android/i.test(userAgent);
		setIsMobile(isMobileDevice);
	}, []);

	if (isMobile) {
		// return <MobileWarning />;
	}

	if ((import.meta.env["VITE_APP_ENVIRONMENT"] as string) === "production") {
		ReactTagManager.init({
			code: import.meta.env["VITE_GTM_ID"] as string,
			debug: false,
			performance: false,
		});
	}

	return (
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
			<Toaster
				position="top-right"
				toastOptions={{
					success: {
						style: {
							background: "#F0FFE5",
						},
						iconTheme: {
							primary: "#55A61C",
							secondary: "white",
						},
					},
					error: {
						style: {
							background: "#FDEDED",
						},
						iconTheme: {
							primary: "#DC0000",
							secondary: "white",
						},
					},
				}}
			></Toaster>
			{/* <TanStackRouterDevelopmentTools
				router={router}
				initialIsOpen={false}
				position="bottom-right"
			/>
			<ReactQueryDevtools initialIsOpen={false} /> */}
		</QueryClientProvider>
	);
};

export default App;
